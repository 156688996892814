import React, { useEffect, useState } from "react";
import styles from "./Blogs.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Blogs() {
  function createMarkup() {
    return { __html: "First &middot; Second" };
  }

  let [blogs, setBlogs] = useState([]);

  let url = "https://avon.admin.simboz.website/api/blogs/getAllBlogs";
  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setBlogs(res.data.blogs);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>Blogs - Avon Commercial</title>
      </Helmet>
      <div className={"blogdetailpage"}>
        <div className={"blogbanner"}>
          <h1>Blogs</h1>
        </div>
        {blogs && blogs != "" ? (
          <div className={"bloglistingpage"}>
            {blogs.map((item, index) => {
              return (
                <Link key={index} to={`/blog/${item.blog_slug}`}>
                  <div className={"mapblogcont"}>
                    <img src={`https://avon.admin.simboz.website/storage/${item.thumbnail}`} />
                    <p>{item.title}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : (
          <div className={"loader"}>
            <img
              src="/assets/service/loadingicon.gif"
              width={100}
              height={100}
              className={"image"}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Blogs;
