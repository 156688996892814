import React, { useState, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./css/Footer.scss";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import {
  FaMapMarkerAlt,
  FaLinkedinIn,
  FaPhoneAlt,
  FaEnvelope,
  FaGoogle,
} from "react-icons/fa";
function Footer() {
  // navigate to about

  let navigate = useNavigate();

  let urlc = window.location.href.split("/").at(-1);

  const [isSubmit, setIsSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true });
  let initialVailue = {
    name: "",
    email: "",
    number: "",
    Subject: "",
    message: "",
  };

  
  // const url = "https://avon.admin.simboz.website/api/contact-queries";
  const url = "https://avon.admin.simboz.com/public/api/send-contact-query";

  const [formData, setFormData] = useState(initialVailue);

  const getData = (e) => {
    e.preventDefault();
    console.log(e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (data) => {
    const { name, email, number, Subject, message } = formData;
    console.log(name);
    console.log(email);
    console.log(number);

    if ((name != "", email != "", number != "", Subject != "", message != "")) {
      console.log(formData);

      const _formaData = new FormData();
      _formaData.append("name", name);
      _formaData.append("email", email);
      _formaData.append("number", number);
      _formaData.append("company", Subject);
      _formaData.append("query", message);

      axios
        .post(url, _formaData)
        .then((res) => {
          console.log(res);
          if (res.data) {
            Swal.fire(
              "Query Sent Successfully",
              "Thank you for contacting Us, One of our representative will get back to you shortly",
              "success"
            );
            setFormData({
              name: null,
              email: null,
              number: null,
              Subject: null,
              message: null,
            });
            navigate("/form-submit");
          }
        })
        .then(reset);
    } else {
    }
  };
  return (
    <div className="footer" id="footerForm">
      <div className="innerfooter">
        <div className="flogo">
          <img
            src={"/assets/logo/avon.svg"}
            alt="Avon Commercial Corporation"
          />

          <div className="infosec">
            <p className="formhedi">Contact Information</p>
            <div className="inner">
              <span>
                <FaMapMarkerAlt
                  className="icon"
                  aria-label="Avon Commercial Corporation Location"
                />
              </span>
              <a href="https://goo.gl/maps/DS6Etw42wAzM5QHK6" target="_blank">
                Address: Building # 5-C, Second Floor, 26th Street,
                Khayaban-e-Sehar, Phase 6, D.H.A, Karachi, Pakistan.
              </a>
            </div>
            <div className="inner">
              <span>
                <FaPhoneAlt
                  className="icon"
                  aria-label="Avon Commercial Corporation Contact"
                />
              </span>
              <p>
                Phone:{" "}
                <a href="tel:+(92-21) 35241213-5"> +(92-21) 35241213-5 </a>
                <br />
                Cell: <a href="tel:03451507878 "> +(92) 3451507878 </a>
              </p>
            </div>
            <div className="inner">
              <span>
                <FaEnvelope
                  className="icon"
                  aria-label="Avon Commercial Corporation Email"
                />
              </span>
              <div>
                <a href="mailto:avon@avoncommercial.net">
                  Email: avon@avoncommercial.net
                </a>
                <br />
                <a href="mailto:avoncom@yahoo.com">Email: avoncom@yahoo.com</a>
              </div>
            </div>
          </div>

          <div className="socialicon">
            <span className="icon">
              <a
                href="https://m.facebook.com/avoncommercial/"
                target={"_blank"}
              >
                {" "}
                <FaFacebookF aria-label="Facebook" />{" "}
              </a>
            </span>
            <span className="icon">
              <a
                href="https://www.linkedin.com/company/avon-commercial-corporation/about/?viewAsMember=true"
                target={"_blank"}
              >
                {" "}
                <FaLinkedinIn aria-label="Linkedin" />{" "}
              </a>
            </span>
            <span className="icon">
              <a
                href="https://www.google.com/maps/place/Avon+Commercial+Corporation/@24.7960665,67.0510251,15z/data=!4m5!3m4!1s0x0:0xe135ad76ec30880a!8m2!3d24.7960665!4d67.0510251"
                target={"_blank"}
              >
                {" "}
                <FaGoogle aria-label="Google Map" />{" "}
              </a>
            </span>
          </div>
        </div>
        {urlc != "contact" ? (
          <div className="footercol">
            <p className="formhedi">Quick Form</p>
            <form onSubmit={handleSubmit(onSubmit)} onKeyUp={getData}>
              <div className="contactinnerright">
                <div className="twofc">
                  <input
                    placeholder="Full Name*"
                    required
                    type="text"
                    // name="name"

                    {...register("name", {
                      required: "name is Required",
                    })}
                  />
                  <input
                    placeholder="Email*"
                    required
                    type="email"
                    // name="email"

                    {...register("email", {
                      required: "email is Required",
                      // maxLength: 20,
                    })}
                  />
                  <input
                    placeholder="Number*"
                    required
                    type="text"
                    // name="number"

                    {...register("number", {
                      required: "number is Required",
                      maxLength: 20,
                      pattern: {
                        value: /^\+?\d+/i,
                        message: "error message",
                      },
                    })}
                  />
                </div>
                {/* -- */}
                <input
                  placeholder="Subject*"
                  required
                  type="text"
                  {...register("Subject", {
                    required: "Subject is Required",
                  })}
                />
                {/*  */}
                <textarea
                  placeholder="Message*"
                  required
                  type="text"
                  // name="email"

                  {...register("message", {
                    required: "message is Required",
                    // maxLength: 20,
                  })}
                />

                <button type="submit" className="contactbtn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="innerfooterquote">
            <div className="footerquote">
              <h1>
                <FaQuoteLeft className="icon1" /> It is better to aim high and
                miss
              </h1>
              <h1>
                than to aim low and hit. <FaQuoteRight className="icon2" />
              </h1>

              <h1 className="quoteby">- Les Brown</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Footer;
